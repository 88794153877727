import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {
  UserGeneralAccessGridComponent
} from '../../../user-management/user-general-access-grid/user-general-access-grid.component';
import {AddUserFormGroup} from '../../../enums/add-user-form.enum';

@Component({
  selector: 'app-general-access-permission-type-renderer',
  templateUrl: './general-access-permission-type-renderer.component.html',
  styleUrls: ['./general-access-permission-type-renderer.component.scss']
})
export class GeneralAccessPermissionTypeRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  parentComponent: UserGeneralAccessGridComponent;
  generalAccessPermissionType = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.parentComponent = this.params.context.this;
    this.populateEditUserData();
  }

  private populateEditUserData() {
    if (this.parentComponent.addUserFormControl.generalAccess.controls
        [this.params.node.rowIndex].controls.permissionType.value === 'Read') {
      this.parentComponent.gridApi.getRowNode(String(this.params.node.rowIndex)).setSelected(true);
      this.generalAccessPermissionType = 'VIEW';
    } else if (this.parentComponent.addUserFormControl.generalAccess.controls
        [this.params.node.rowIndex].controls.permissionType.value === 'Write') {
      this.parentComponent.gridApi.getRowNode(String(this.params.node.rowIndex)).setSelected(true);
      this.generalAccessPermissionType = 'VIEW_EDIT';
    } else {
      this.parentComponent.gridApi.getRowNode(String(this.params.node.rowIndex)).setSelected(false);
      this.generalAccessPermissionType = '';
    }
  }

  resetGeneralAccessPermissionType() {
    this.generalAccessPermissionType = '';
  }
  refresh(params: ICellRendererParams<any>): boolean {
    this.params = params;
    return false;
  }

  public generalAccessChanged(params) {
    if (!this.parentComponent.gridApi.getRowNode(params.node.rowIndex).isSelected()) {
      this.parentComponent.gridApi.getRowNode(params.node.rowIndex).setSelected(true);
    }

    this.parentComponent.addUserFormControl.generalAccess.controls
      [params.node.rowIndex].controls.permissionType.setValue(this.generalAccessPermissionType);

    this.parentComponent.addUserFormControl.generalAccess.controls
      [params.node.rowIndex].controls.permissionCode.setValue(this.generalAccessPermissionType + params.data.permissionCodeSuffix);

    this.parentComponent.addUserFormControl.generalAccess.controls
      [params.node.rowIndex].controls.permissionType.updateValueAndValidity();
  }

  isFormControlInvalid() {
    return this.parentComponent?.addUserFormControl[AddUserFormGroup.GENERAL_ACCESS].controls[
            this.params.node.rowIndex].controls.permissionType.errors?.required && this.parentComponent.formSubmitted;
  }
}
