import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {UserManagementActions} from '../../models/user-management-actions';
import {environment} from 'src/environments/environment';
import {PBMUserInfo} from 'src/app/models/User';
import {HttpHeaderService} from 'src/app/services/http-header/http-header.service';
import {ServerSideUtils} from '../../utils/server-side/serverSideUtils';
import {SortModelItem} from 'ag-grid-community';
import {FilterType} from '../../enums/filter.enum';
import {ExcelEnum} from '../../enums/excel.enum';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private umBaseUrl = '/api/v1/userManagement';
  private excelBaseUrl = '/api/v1/excel';
  private clientBaseUrl = '/api/v1/client/';

  userManagementNotification = new BehaviorSubject<UserManagementActions>(null);

  mapColumnNameToQueryParam: Map<string, string> = new Map([
    ['basicUserInfo.active', 'active'],
    ['appRoles', 'appName'],
  ]);

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) {
  }

  getLatestAccessRequestForm() {
    return this.httpClient.get('assets/excelFiles/' + ExcelEnum.LATEST_BULK_USER_FILE, {responseType: 'blob'});
  }

  private buildSimpleHeader(params?) {
    return this.httpHeaderService.buildSimpleHeader(params);
  }

  addUser(user: PBMUserInfo) {
    return this.httpClient.post(environment.portApiBaseUrl + this.umBaseUrl + '/user', user, this.buildSimpleHeader());
  }

  updateUser(user: PBMUserInfo) {
    return this.httpClient.put(environment.portApiBaseUrl + this.umBaseUrl + '/user', user,  this.buildSimpleHeader());
  }

  updateTerms(accept: boolean) {
    return this.httpClient.post(environment.portApiBaseUrl + this.umBaseUrl
      + '/userPreferences/terms?accepted=' + accept ,  null, this.buildSimpleHeader());
  }

  getUsers(startRow: number, endRow: number, filterModel: any, sortModel: SortModelItem[]) {
    const url = environment.portApiBaseUrl + this.umBaseUrl + '/users';
    const limit = endRow - startRow;

    const queryParamMap = new Map<string, any>([
      ['limit', limit],
      ['offset', startRow]
    ]);

    queryParamMap.set('sort', this.createSortQueryParams(sortModel));

    this.addFilterQueryParams(filterModel, queryParamMap);

    const queryParams = Object.fromEntries(queryParamMap);

    return this.httpClient.get(url,  this.buildSimpleHeader(queryParams));
  }

  private addFilterQueryParams(filterModel: any, queryParamMap: Map<string, any>) {
    for (const filter in filterModel) {

      switch (filterModel[filter].filterType) {

        case FilterType.SET:
            const queryParamKey = this.mapColumnNameToQueryParam.has(filter) ? this.mapColumnNameToQueryParam.get(filter) : filter;
            const queryParamValue = filterModel[filter].values.join();
            queryParamMap.set(queryParamKey, queryParamValue);
            break;

        case FilterType.TEXT:
          if (filter.includes('.')) {
            const field = filter.substring(filter.indexOf('.') + 1);
            queryParamMap.set(field, filterModel[filter].type + ',' + filterModel[filter].filter);
          } else {
            queryParamMap.set(filter, filterModel[filter].type + ',' + filterModel[filter].filter);
          }
          break;

        default:
          break;
      }
    }
  }

  private createSortQueryParams(sortModel: SortModelItem[]): string[] {
    const sortParams: string[] = [];

    for (const sort of sortModel) {
      const fieldName = sort.colId;

      if (fieldName.includes('.')) {
        const strippedFieldName = fieldName.substring(fieldName.indexOf('.') + 1);
        sortParams.push(strippedFieldName + ',' + sort.sort);
      } else {
        sortParams.push(fieldName + ',' + sort.sort);
      }
    }

    return sortParams;
  }

  getUserByEmail(email): Observable<PBMUserInfo> {
    return this.httpClient.get<PBMUserInfo>(environment.portApiBaseUrl + this.umBaseUrl + '/user/' + email, this.buildSimpleHeader() );
  }

  getUser(): Observable<PBMUserInfo> {
    return this.httpClient.get<PBMUserInfo>(environment.portApiBaseUrl + this.umBaseUrl + '/user/', this.buildSimpleHeader() );
  }

  patchUser(pbmPatchUserRequest, id) {
    return this.httpClient.patch(
      environment.portApiBaseUrl + this.umBaseUrl + '/user/' + id,
      pbmPatchUserRequest,
      this.buildSimpleHeader()
    );
  }

  getUserSuperClients( email: string, startRow: number, endRow: number): Observable<any>  {
    const queryParamMap = new Map<string, any>();
    ServerSideUtils.addPaginationQueryParams(startRow, endRow, queryParamMap);
    return this.httpClient.get(environment.portApiBaseUrl + this.umBaseUrl + '/user/' + email + '/superClients',
      this.buildSimpleHeader(Object.fromEntries(queryParamMap)));
  }

  getUserClients( email: string, startRow: number, endRow: number): Observable<any>  {
    const queryParamMap = new Map<string, any>();
    ServerSideUtils.addPaginationQueryParams(startRow, endRow, queryParamMap);
    return this.httpClient.get(environment.portApiBaseUrl + this.umBaseUrl + '/user/' + email + '/clients',
      this.buildSimpleHeader(Object.fromEntries(queryParamMap)));
  }

  getUserCarriers( email: string, startRow: number, endRow: number): Observable<any>  {
    const queryParamMap = new Map<string, any>();
    ServerSideUtils.addPaginationQueryParams(startRow, endRow, queryParamMap);
    return this.httpClient.get(environment.portApiBaseUrl + this.umBaseUrl + '/user/' + email + '/carriers',
      this.buildSimpleHeader(Object.fromEntries(queryParamMap)));
  }

  getUserCounts(): Observable<any> {
    return this.httpClient.get<any>(
      environment.portApiBaseUrl + this.umBaseUrl + '/users/counts', this.buildSimpleHeader()
    );
  }

  getActiveUsers(queryParamMap: Map<string, any>): Observable<PBMUserInfo[]> {
    queryParamMap.set('active', 'true');
    queryParamMap.set('sort', 'lastName,asc');

    return this.httpClient.get<PBMUserInfo[]>(
      environment.portApiBaseUrl + this.umBaseUrl + '/users/partial', this.buildSimpleHeader(Object.fromEntries(queryParamMap))
    );
  }

  uploadFile(documentUpload: FormData): Observable<Blob> {
    return this.httpClient.post(
      // eslint-disable-next-line max-len
      environment.portApiBaseUrl + this.excelBaseUrl + '/upload/users', documentUpload, this.httpHeaderService.buildSimpleExcelUploadHeader()
    );
  }
}
