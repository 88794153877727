import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientSearchType} from '../../enums/client-search-type.enum';
import {ClientHierarchyEnum} from '../../enums/client-hierarchy-enum';
import {
  actions,
  clientGroupNameManagement,
  Clients,
  createdBy,
  createdDate,
  groupCarrierName,
  groupClientCode,
  groupType,
  lastModifiedBy,
  lastModifiedDate,
  lineOfBusiness,
  owner,
  ownerEmail,
  status,
  superClientIDGroupName,
  superClientNameGroupName
} from '../../models/ClientGroupColumns';
import {ClientGroupManagementService} from '../../services/client-group-management/client-group-management.service';
import {FormBuilder} from '@angular/forms';
import {ClientProfileModalService} from '../../services/client-profile/modal/client-profile-modal.service';
import {ColumnApi, GridApi, IServerSideDatasource, IServerSideGetRowsParams, RowModelType} from 'ag-grid-community';
import {HttpErrorResponse} from '@angular/common/http';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {PBMUserInfo} from '../../models/User';
import {ClientSearchComponent} from '../client-search/client-search.component';
import {AgGridHelper} from '../helpers/AgGridHelper';
import {
  ClientGroupTableActionCellRendererComponent
} from '../cell-renderers/client-group-table-action-cell-renderer/client-group-table-action-cell-renderer.component';
import {ClientDTO} from '../../models/ClientDTO';
import {
  ClientGroupManagementComponent
} from '../../client-management/client-group-management/client-group-management.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-client-group-search',
  templateUrl: './client-group-search.component.html',
  styleUrls: ['./client-group-search.component.scss']
})
export class ClientGroupSearchComponent implements OnInit {
  @Input() parentComponent: ClientGroupManagementComponent;

  frameworkComponents = {
    clientGroupManagementTableActionCellRenderer: ClientGroupTableActionCellRendererComponent
  };

  @ViewChild('appClientSearch') clientSearch: ClientSearchComponent;

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    rowSelection: 'multiple',
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset', 'apply'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  superClientIdColumnDefs = [
    clientGroupNameManagement,
    superClientIDGroupName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  superClientGroupNameColumnDefs = [
    clientGroupNameManagement,
    superClientNameGroupName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  groupClientCodeColumnDefs = [
    clientGroupNameManagement,
    groupClientCode,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  groupCarrierIdColumnDefs = [
    clientGroupNameManagement,
    groupCarrierName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  groupCarrierNameColumnDefs = [
    clientGroupNameManagement,
    groupCarrierName,
    Clients,
    groupType,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  clientGroupNameColumnDefs = [
    clientGroupNameManagement,
    Clients,
    groupType,
    owner,
    ownerEmail,
    lineOfBusiness,
    status,
    actions,
    createdDate,
    lastModifiedDate,
    createdBy,
    lastModifiedBy
  ];

  clientDropdownOptions = [
    {
      label: ClientSearchType.CLIENT_GROUP_NAME,
      colDef: this.clientGroupNameColumnDefs,
      searchParam: 'groupName',

    },
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      colDef: this.superClientIdColumnDefs,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      colDef: this.superClientGroupNameColumnDefs,
      searchParam: 'superClientName',
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      colDef: this.groupClientCodeColumnDefs,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CARRIER_ID,
      colDef: this.groupCarrierIdColumnDefs,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      colDef: this.groupCarrierNameColumnDefs,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
  ];

  showGrid = false;
  showErrorResponse: boolean;
  context = {this: this};
  isGridReady = false;
  rowModelType: RowModelType = 'serverSide';
  clientDropdownOption;
  gridApi: GridApi;
  columnApi: ColumnApi;
  isButtonShown = false;
  clientSearchType: ClientSearchType;
  currentUser: PBMUserInfo;
  defaultPaginatorSize = 25;


  constructor(private clientGroupManagementService: ClientGroupManagementService,
              private formBuilder: FormBuilder,
              private clientProfileModalService: ClientProfileModalService,
              private currentUserService: CurrentUserService,
              private datePipe: DatePipe,
  ) {
    this.currentUser = this.currentUserService.currentUser$.value;
  }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.isGridReady = true;
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.createServerSideData();

    AgGridHelper.insertRowsPerPageSelector('userClientGroupGrid', 'userClientGroupPaginator');
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
  }

  onDropDownChange() {
    this.clientSearchType = this.clientDropdownOption.label;
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.gridApi.deselectAll();
        this.gridApi.setColumnDefs(this.clientDropdownOption.colDef);
        this.clientGroupManagementService.searchClientGroupManagement(
          this.clientDropdownOption.searchParam,
          params.request.startRow,
          params.request.endRow,
          params.request.filterModel).subscribe({
          next: this.handleGetClientGroupDataSuccess.bind(this, params),
          error: this.handleGetClientGroupDataFailure.bind(this, params)
        });
      },
    };
  }

  createServerSideData() {
    const datasource: IServerSideDatasource = this.createServerSideDatasource();
    this.gridApi.setServerSideDatasource(datasource);
  }

  searchClientGroup(searchValue: string) {
    this.clientGroupManagementService.globalSearchValue = searchValue;
    this.showGrid = true;
    this.parentComponent.showGrid = true;
    this.parentComponent.hideGroupDetails();
    this.showErrorResponse = false;

    if (this.isGridReady) {
      this.createServerSideData();
    }
    this.isButtonShown = true;
  }

  onClientSearchValidationError() {
    this.showErrorResponse = false;
  }

  private handleGetClientGroupDataSuccess(params: IServerSideGetRowsParams, response: any) {
    params.success({
      rowData: response.data
    });
    this.clientSearch.clientSearchType = this.clientDropdownOption.label;
  }

  private handleGetClientGroupDataFailure(params: IServerSideGetRowsParams, err: HttpErrorResponse) {
    params.fail();
    this.showErrorResponse = true;
    this.showGrid = false;
  }

  resetGroupSearch() {
    this.isButtonShown = false;
    this.clientDropdownOption = null;
    this.clientSearch.searchValue.reset();
    this.showGrid = false;
    this.parentComponent.showGrid = false;
    this.showErrorResponse = false;
    this.clientSearch.clientSearchLabel = '';
    this.clientSearchType = null;
    this.clientSearch.searchValue.clearValidators();
    this.clientSearch.searchValue.setErrors(null);
    this.parentComponent.hideGroupDetails();
  }

  public getClientName(params): string {
    let clientName = '';
    const searchParam = this.clientDropdownOption.searchParam;

    switch (searchParam){
      case 'superClientId':
        clientName = this.clientIdentifierContainsSearchValue(params, 'superClientId')[0].superClientName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'superClientId')[0].superClientId + ')';
        break;
      case 'superClientName':
        clientName = this.clientIdentifierContainsSearchValue(params, 'superClientName')[0].superClientName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'superClientName')[0].superClientId  + ')';
        break;
      case 'carrierId':
        clientName =  this.clientIdentifierContainsSearchValue(params, 'carrierId')[0].carrierName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'carrierId')[0].carrierId  + ')';
        break;
      case 'carrierName':
        clientName =  this.clientIdentifierContainsSearchValue(params, 'carrierName')[0].carrierName + ' (' +
          this.clientIdentifierContainsSearchValue(params, 'carrierName')[0].carrierId  + ')';
        break;
      default: break;
    }
    return clientName;
  }

  private clientIdentifierContainsSearchValue(params, fieldToFilter) {
    return params.data.clients.filter(client => client[fieldToFilter].toUpperCase()
      .includes(this.clientGroupManagementService.globalSearchValue.toUpperCase()));
  }

  public openClientProfileModal(params) {
    const client = this.getClient(params);
    const isFromCarrier = client.accountLevel === ClientHierarchyEnum.CARRIER ? true : null;
    this.clientProfileModalService.openClientProfileModal(client, isFromCarrier);
  }

  private getClient(params): ClientDTO {
    const searchParam = this.clientDropdownOption.searchParam;
    return this.clientIdentifierContainsSearchValue(params, searchParam)[0];
  }
}
