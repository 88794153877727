<div class="client-group-dropdown">
  <mat-form-field class="user-client-select">
    <mat-label aria-label="Select Option">Select Option</mat-label>
    <mat-select id="userClientAccessSelection" [disableOptionCentering]="true"
                [(value)]="clientDropdownOption"
                (selectionChange)="onDropDownChange()">
      <mat-option *ngFor="let clientDropdownOption of clientDropdownOptions;"
                  [value]="clientDropdownOption">
        {{clientDropdownOption.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-client-search
    style="display: inline-block; padding-bottom: 20px; padding-left: 24px " #appClientSearch
    [buttonClass]="'cvs-btn-ghost'"
    [clientSearchType]="clientSearchType"
    (searchOutput)="searchClientGroup($event)"
    (validationError)="onClientSearchValidationError()"
  >
  </app-client-search>
  <button *ngIf="isButtonShown"
          id="clear-client-group-access-tab-results"
          aria-label="Clear Results Button"
          mat-button color="primary"
          type="button"
          (click)="resetGroupSearch()">
    Clear Results
  </button>
</div>

<div class="client-error-text-message" *ngIf="showErrorResponse">
  No client group(s) to display, search again or contact your client administrator to add the client group.
</div>

<ag-grid-angular *ngIf="showGrid"
                 [columnDefs]="superClientIdColumnDefs"
                 [defaultColDef]="defaultColDef"
                 (gridReady)="onGridReady($event)"
                 [rowHeight]="32"
                 [context]="context"
                 [suppressMenuHide]="true"
                 [components]="frameworkComponents"
                 [rowModelType]="rowModelType"
                 [headerHeight]="31"
                 [pagination]="true"
                 class="ag-theme-alpine client-group-access-grid"
                 id="userClientGroupGrid">
</ag-grid-angular>
<app-paginator-rows-per-page
  *ngIf="showGrid"
  [defaultPaginatorSize]="defaultPaginatorSize"
  [pageSizeOptions]="[25, 50, 100]"
  [gridApi]="gridApi"
  [columnApi]="columnApi"
  id="userClientGroupPaginator">
</app-paginator-rows-per-page>
