import {SelectionModel} from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Router} from '@angular/router';
import {
  CVSBannerComponentData,
  CVSBannerService,
  CVSBannerType,
  CVSConfirmationDialogContentComponent
} from 'angular-component-library';
import {BehaviorSubject, Subject, take} from 'rxjs';
import {UserManagementService} from '../../services/user-management/user-management.service';
import {AppManagementService} from '../../services/app-management/app-management.service';
import {PBMAppInfo} from '../../models/AppInfo';
import {UserActions, UserManagementActions} from '../../models/user-management-actions';
import {UserAccessType} from '../../enums/add-user-form.enum';
import {AgGridAngular} from 'ag-grid-angular';
import {
  ColDef,
  ColumnApi,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  Module,
  RowModelType,
  ServerSideStoreType
} from 'ag-grid-community';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {FilterValue} from '../../enums/filter.enum';
import {PaginatedResponse} from '../../models/PaginatedResponse';
import {HttpErrorResponse} from '@angular/common/http';
import {
  UserAppAccessRendererComponent
} from '../../ag-grid-utils/cell-renderers/user-app-access-renderer/user-app-access-renderer.component';
import {PBMUserInfo} from '../../models/User';
import {
  UserManagementTableActionCellRendererComponent
} from '../../ag-grid-utils/cell-renderers/user-management-table-action-cell-renderer/user-management-table-action-cell-renderer.component';
import {CurrentUserService} from '../../services/current-user/current-user.service';
import {AddEditModeEnum} from '../../enums/add-edit-form.enum';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ExcelEnum} from '../../enums/excel.enum';
import * as XLSX from 'xlsx';

export interface UserTabInfo {
  label: string;
  filterColumn: string;
  filterValues: any;
  contentSize: number;
}

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('customBannerContentTemplate') customBannerContentTemplate: TemplateRef<any>;
  @ViewChild(AgGridAngular) agGrid: AgGridAngular;

  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;
  areAccessRequestFormVersionSame: Subject<boolean> = new Subject<boolean>();

  focusFirstCell = AgGridHelper.focusFirstCell;

  currentUser: PBMUserInfo;
  modules: Module[] = [];

  context = {this: this};

  gridApi: GridApi;
  columnApi: ColumnApi;

  rowModelType: RowModelType = 'serverSide';
  serverSideStoreType: ServerSideStoreType = 'partial';

  frameworkComponents = {
    userManagementTableActionCellRendererComponent: UserManagementTableActionCellRendererComponent
  };

  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    comparator: (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  columnDefs = [
    {
      headerName: 'Last Name',
      field: 'basicUserInfo.lastName',
      width: 100,
      minWidth: 100,
      flex: 1,
      sort: 'asc'
    },
    {
      headerName: 'First Name',
      field: 'basicUserInfo.firstName',
      width: 100,
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: 'Middle Initial',
      field: 'basicUserInfo.middleName',
      width: 100,
      minWidth: 100,
      flex: .8,
      valueGetter: (params) => {
        return params.data.basicUserInfo.middleName?.charAt(0);
      },
    },
    {
      headerName: 'Email Address',
      field: 'basicUserInfo.email',
      width: 150,
      minWidth: 100,
      flex: 2,
    },
    {
      headerName: 'Status',
      field: 'basicUserInfo.active',
      width: 150,
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        return params.data.basicUserInfo.active ? 'Active' : 'Inactive';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [true, false],
        valueFormatter: (params) => {
          if (params.value === FilterValue.SELECT_ALL) {
            return FilterValue.SELECT_ALL;
          } else {
            return (params.value === 'true') ? 'Active' : 'Inactive';
          }
        },
      }
    },
    {
      headerName: 'Location',
      field: 'onshore',
      width: 60,
      minWidth: 60,
      flex: .8,
      sortable: false,
      valueGetter: (params) => {
        return params.data.onshore ? 'Onshore' : 'Offshore';
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [true, false],
        valueFormatter: (params) => {
          if (params.value === FilterValue.SELECT_ALL) {
            return FilterValue.SELECT_ALL;
          } else {
            return (params.value === 'true') ? 'Onshore' : 'Offshore';
          }
        },
      }
    },
    {
      field: 'accessType',
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['ALL', UserAccessType.CVS_HEALTH_COLLEAGUE, UserAccessType.CLIENT_USER, UserAccessType.CONSULTANT_USER]
      }
    },
    {
      headerName: 'App Access',
      field: 'appRoles',
      minWidth: 180,
      flex: 2,
      sortable: false,
      valueGetter: (params) => {
        return params.context.this.getUserAppCodes(params.data);
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => {
          const filterOptions = [];

          for (const app of params.context.this.allApps) {
            filterOptions.push(app.appName);
          }

          params.success(filterOptions);
        },
        comparator: (a, b) => {
          return a.localeCompare(b);
        },
      },
      cellRenderer: UserAppAccessRendererComponent
    },
    {
      headerName: 'User Actions',
      field: '',
      sortable: false,
      suppressMenu: true,
      minWidth: 100,
      cellRenderer: 'userManagementTableActionCellRendererComponent',
    }
  ] as ColDef[];

  adminTypeColumn: any = {
    headerName: 'Admin Type',
    field: 'adminType',
    flex: 2,
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      return params.data.adminType?.split(', ');
    },
    filterParams: {
      defaultToNothingSelected: true,
      context: this.context,
      values: (params) => {
        params.success(params.colDef.filterParams.context.this.adminTypes);
      },
      suppressSorting: true
    },
    sortable: false,
  };

  employeeIdColumn = {
    headerName: 'Employee ID',
    field: 'employeeId',
    width: 150,
    minWidth: 100,
    flex: 1,
  };


  title = 'User Management';
  bannerData: CVSBannerComponentData;
  subscriptions: any[] = [];
  actionMessage: string;
  actionSubMessage: string;
  showSpinner: Subject<boolean> = new BehaviorSubject(true);
  allAppsDataLoaded$: Subject<boolean> = new BehaviorSubject(false);
  allAppsDataLoaded = false;
  isLoading = false;

  selectionNormal = new SelectionModel<any>(false, []);
  userTabs: UserTabInfo[] = [
    {label: 'All Users', filterColumn: 'accessType', filterValues: [], contentSize: 0},
    {label: 'CVS Health Colleagues', filterColumn: 'accessType', filterValues: [UserAccessType.CVS_HEALTH_COLLEAGUE], contentSize: 0},
    {label: 'Client Users', filterColumn: 'accessType', filterValues: [UserAccessType.CLIENT_USER], contentSize: 0},
    {label: 'Consultant Users', filterColumn: 'accessType', filterValues: [UserAccessType.CONSULTANT_USER], contentSize: 0},
    {label: 'Admins', filterColumn: 'adminType', filterValues: ['myPBM', 'App'], contentSize: 0},
  ];
  allApps: PBMAppInfo[] = [];
  adminTypes = ['myPBM', 'App'];
  defaultPaginatorSize = 50;

  readonly XLSX = {
    read: XLSX.read,
    utils: XLSX.utils
  };

  constructor(
    private userManagementService: UserManagementService,
    private bannerService: CVSBannerService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private appManagementService: AppManagementService,
    private currentUserService: CurrentUserService,
    private matDialog: MatDialog
  ) {
    this.currentUser = this.currentUserService.currentUser$.value;
  }

  ngOnInit(): void {
    const appManagementServiceSub = this.appManagementService.getAllApps().subscribe((response: PBMAppInfo[]) => {
      this.allApps = response.filter(app => app.appName !== 'MYPBM PORTAL');
      this.allApps.sort((app1, app2) => app1.appName > app2.appName ? 1 : -1);
      this.allAppsDataLoaded$.next(true);
    });
    const userCountsServiceSub = this.userManagementService.getUserCounts().subscribe(response => {
      this.userTabs[0].contentSize = response['All Users'];
      this.userTabs[1].contentSize = response[UserAccessType.CVS_HEALTH_COLLEAGUE];
      this.userTabs[2].contentSize = response[UserAccessType.CLIENT_USER];
      this.userTabs[3].contentSize = response[UserAccessType.CONSULTANT_USER];
      this.userTabs[4].contentSize = response['Admin User'];
    });

    this.subscriptions.push(appManagementServiceSub);
    this.subscriptions.push(userCountsServiceSub);
  }


  onGridReady(event: any) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;

    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.gridApi.setCacheBlockSize(this.defaultPaginatorSize);

    const datasource: IServerSideDatasource = this.createServerSideDatasource();
    this.gridApi.setServerSideDatasource(datasource);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const sub = this.userManagementService.userManagementNotification
        .subscribe((actions: UserManagementActions) => {
          if (actions && actions.action === UserActions.USER_ADD && actions.user) {
            this.actionMessage = actions.user.basicUserInfo.firstName + ' ' + actions.user.basicUserInfo.lastName + ' Successfully Added';
            this.actionSubMessage = 'User has been successfully added in myPBM';
            this.sendCustomAlert(CVSBannerType.Success);
          }

          if (actions && actions.action === UserActions.USER_EDIT && actions.user) {
            this.actionMessage = actions.user.basicUserInfo.firstName + ' ' + actions.user.basicUserInfo.lastName + ' Successfully Updated';
            this.actionSubMessage = 'User has been successfully updated in myPBM';
            this.sendCustomAlert(CVSBannerType.Success);
          }
        });
      this.subscriptions.push(sub);

    }, 200);
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.userManagementService.getUsers(params.request.startRow, params.request.endRow,
          params.request.filterModel, params.request.sortModel).subscribe(  {
          next: this.handleGetUsersSuccess.bind(this, params),
          error: this.handleGetUsersFailure.bind(this, params)
        });
      }
    };
  }

  private handleGetUsersSuccess(params: IServerSideGetRowsParams, response: PaginatedResponse) {

    this.enrichUserObjects(response.data);

    params.success({
      rowData: response.data,
      rowCount: response.count
    });
  }

  private enrichUserObjects(users: PBMUserInfo[]) {
    users.forEach(user => {
      this.setAdminType(user);
      this.setUserAppNames(user);
    });
  }

  private handleGetUsersFailure(params: IServerSideGetRowsParams, err: HttpErrorResponse)  {
    params.fail();
  }

  onTabChanged(event) {
    this.gridApi.setFilterModel(null);
    this.setAdminTypeColumn(event);
    this.setEmployeeIdColumn(event);

    if (this.userTabs[event.index].filterValues.length > 0) {
      this.gridApi.setFilterModel({[this.userTabs[event.index].filterColumn]:
          {filterType: 'set', values: this.userTabs[event.index].filterValues}});
    }

    this.gridApi.onFilterChanged();
  }

  private setAdminTypeColumn(event) {
    if (event.index === 4 && !this.columnDefs.includes(this.adminTypeColumn)) {
      this.columnDefs.push(this.adminTypeColumn);
      this.gridApi.setColumnDefs(this.columnDefs);
    } else if (this.columnDefs.includes(this.adminTypeColumn)) {
      this.columnDefs.pop();
      this.gridApi.setColumnDefs(this.columnDefs);
    }
  }
  private setEmployeeIdColumn(event) {
    if (event.index === 1 && !this.columnDefs.includes(this.employeeIdColumn)) {
      const emailColumnIndex  = this.columnDefs.findIndex(column => column.field === 'basicUserInfo.email' );
      this.columnDefs.splice(emailColumnIndex + 1,0,this.employeeIdColumn);
      this.gridApi.setColumnDefs(this.columnDefs);
    } else if (this.columnDefs.includes(this.employeeIdColumn)) {
      this.columnDefs.splice(this.columnDefs.indexOf(this.employeeIdColumn),1);
      this.gridApi.setColumnDefs(this.columnDefs);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  sendCustomAlert(alert: CVSBannerType) {
    this.bannerData = {
      hideX: false,
      bannerType: alert, // required
      closeCallBack: () => this.bannerService.close(),
      outletId: '#userActionBanner', // required
      template: this.customBannerContentTemplate, // required
      viewContainerRef: this.viewContainerRef // required
    };
    this.bannerService.sendAlert(this.bannerData);
    this.userManagementService.userManagementNotification.next(null);
  }

  getUserAppCodes(user) {
    if (user) {
      const userAppCodes = Object.keys(user.appRoles).filter(appCode => appCode !== 'MYPBM');
      userAppCodes.sort((app1, app2) => app1 > app2 ? 1 : -1);
      return userAppCodes;
    }
  }

  navigateToAdd() {
    this.router.navigate(['/user-management/users/add'], {state: {mode: AddEditModeEnum.ADD}});
  }

  navigateToEdit(event, columnsToDisableClick) {
    if (!columnsToDisableClick.includes(event.colDef.headerName)) {
      this.router.navigate(['/user-management/users/edit', event.data.basicUserInfo?.email], {state: {mode: AddEditModeEnum.EDIT}});
    }
  }

  private setAdminType(user) {
    Object.keys(user.appRoles).forEach(key => {
      if (user.appRoles[key].filter(roles => !roles.includes('MYPBM_ADMIN')).some(role => role.endsWith('_ADMIN'))) {
          user.adminType = 'App';
        }
      });

      if (user.adminUser) {
        user.adminType = user.adminType === 'App' ? 'myPBM, App' : 'myPBM';
      }
  }

  private setUserAppNames(user) {
    const sub = this.allAppsDataLoaded$.subscribe( isAllAppsDataLoaded => {
      if (isAllAppsDataLoaded) {
        user.userAppNames = new Set([]);
        Object.keys(user.appRoles).forEach(appCode => {
          this.allApps.forEach(app =>  {
            if(app.appCode === appCode){
              user['userAppNames'].add(app.appName);
            }
          });
        });
        this.allAppsDataLoaded = true;
      }
    });
    this.subscriptions.push(sub);
  }

  navigateOnEnterOrSpace(cellKeyPressEvent) {
    if(cellKeyPressEvent.event.code === 'Enter' || cellKeyPressEvent.event.code === 'Space') {
      this.navigateToEdit(cellKeyPressEvent, ['App Access']);
    }
  }

  downloadAccessRequestForm() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = `assets/excelFiles/${ExcelEnum.LATEST_BULK_USER_FILE}`;
    link.download = ExcelEnum.LATEST_BULK_USER_FILE;
    document.body.appendChild(link);
    link.click();
  }

  downloadErrorExcel(errorExcel: Blob) {
    const url = URL.createObjectURL(errorExcel);
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    downloadLink.download = ExcelEnum.LATEST_BULK_USER_FILE_ERROR_REPORT;
    downloadLink.click();
    URL.revokeObjectURL(url);
  }

  openUploadConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Confirm the latest version of myPBM Access Request Form',
        body:
          '<br>'
          + 'Make sure you have the latest version of the myPBM access request form before uploading it.'
          + '<br>',
        actionLabel: 'Ok',
        noCloseX: false
      }
    });
    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.uploadFile();
    });
  }

  uploadFile() {
    const input: HTMLInputElement = document.querySelector('#upload-file-input');
    input.value = '';
    input.click();
  }

  checkIfUploadedFormIsLatest(uploadedAccessReqForm: any) {
    let uploadedExcelData = [];
    let latestExcelData = [];

    this.userManagementService.getLatestAccessRequestForm().subscribe(latestAccessReqForm => {

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = this.XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        latestExcelData = this.XLSX.utils.sheet_to_json(ws, {header: 1});

        const reader2: FileReader = new FileReader();
        reader2.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = this.XLSX.read(bstr, {type: 'binary'});

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          uploadedExcelData = this.XLSX.utils.sheet_to_json(ws, {header: 1});

          if (this.areArraysEqual(latestExcelData[4], uploadedExcelData[4])) {
            // check rows are empty
            const row7Empty = this.isRowEmpty(uploadedExcelData[6]);
            const row8Empty = this.isRowEmpty(uploadedExcelData[7]);

              if (row7Empty && row8Empty) {
                this.isLoading = false;
                const bannerData = {
                  hideX: false,
                  headline: 'Upload Failed',
                  // eslint-disable-next-line max-len
                  body: 'The myPBM access request form is empty, at least one user must be submitted for access. Please review and re-submit myPBM access request form.',
                  outletId: '#userActionBanner'
                };
                this.sendAlert(bannerData, CVSBannerType.Error);
                return;
              }

            this.areAccessRequestFormVersionSame.next(true);
          } else {
            this.isLoading = false;
            this.areAccessRequestFormVersionSame.next(false);
            const bannerData = {
              hideX: false,
              headline: 'Upload Failed',
              body: 'Please ensure you are using the latest version of the myPBM Access Request Form.' +
                '\nYou can download the latest form and try uploading it again.',
              outletId: '#userActionBanner'
            };
            this.sendAlert(bannerData, CVSBannerType.Error);
          }
        };
        reader2.readAsBinaryString(uploadedAccessReqForm);
      };
      reader.readAsBinaryString(latestAccessReqForm);
    });
  }

  areArraysEqual(latestColumnHeaderText: any, uploadedColumnHeaderText: any) {
    if (latestColumnHeaderText === uploadedColumnHeaderText) {
      return true;
    }
    if (latestColumnHeaderText == null || uploadedColumnHeaderText == null) {
      return false;
    }
    if (latestColumnHeaderText === undefined || uploadedColumnHeaderText === undefined) {
      return false;
    }
    if (latestColumnHeaderText.length !== uploadedColumnHeaderText.length) {
      return false;
    }

    for (let i = 0; i < latestColumnHeaderText.length; ++i) {
      const currentLatestColumnHeaderText = latestColumnHeaderText[i].replace(/\r|\n/g, '');
      const currentUploadedColumnHeaderText = uploadedColumnHeaderText[i].replace(/\r|\n/g, '');

      if (currentLatestColumnHeaderText !== currentUploadedColumnHeaderText) {
        return false;
      }
    }
    return true;
  }

  isRowEmpty(row: any[]): boolean {
    for (const cell of row) {
      if (cell !== null && cell !== undefined && cell.toString().trim() !== '') {
        return false;
      }
    }
    return true;
  }

  onFileSelected(event: any) {
    this.isLoading = true;

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    this.checkIfUploadedFormIsLatest(file);

    const sub = this.areAccessRequestFormVersionSame.pipe(take(1)).subscribe((areAccessRequestFormVersionSame) => {
      if (areAccessRequestFormVersionSame) {
        this.processUploadedFile(file);
      }
    });

    this.subscriptions.push(sub);
  }

  private processUploadedFile(file: Blob) {
    const formData = new FormData();
    formData.set('upload', file);

    this.userManagementService.uploadFile(formData).subscribe({
      next: response => {
        this.isLoading = false;
        const bannerData = {
          hideX: false,
          headline: 'Upload Successful',
          body: 'The access form has been uploaded and all users have been processed successfully.',
          outletId: '#userActionBanner'
        };
        this.sendAlert(bannerData, CVSBannerType.Success);
        this.gridApi.refreshServerSide({purge: true});
      }, error: error => {
        this.isLoading = false;
        const bannerData = {
          hideX: false,
          headline: 'Upload Failed',
          body: 'Errors prevented the file from processing, review the downloaded error report and determine which items need correction.',
          outletId: '#userActionBanner'
        };
        if (error.status === 400) {
          this.sendAlert(bannerData, CVSBannerType.Error);
          this.downloadErrorExcel(error.error);
        } else {
          const bannerError = {
            hideX: false,
            headline: 'Upload Failed',
            body: 'Unable to process request.',
            outletId: '#userActionBanner'
          };
          this.sendAlert(bannerError, CVSBannerType.Error);
        }
        this.gridApi.refreshServerSide({purge: true});
      }
    });
  }

  sendAlert(bannerData, alert: CVSBannerType) {
    this.bannerData = {
      bannerType: alert,
      hideX: bannerData.hideX,
      closeCallBack: () => this.bannerService.close(),
      headline: bannerData.headline,
      body: bannerData.body,
      outletId: bannerData.outletId,
      bannerLinks: bannerData.bannerLinks
    };
    this.bannerService.sendAlert(this.bannerData);
  }
}
