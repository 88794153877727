import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpHeaderService} from '../http-header/http-header.service';
import {PBMAppInfo, PBMRoleInfo} from '../../models/AppInfo';
import {PBMPermissionInfo} from '../../models/PBMPermissionInfo';
import {BannerData} from '../../models/BannerData';


@Injectable({
  providedIn: 'root'
})
export class AppManagementService {
  private baseUrl = '/api/v1/appmanagement/';

  appManagementNotification: BehaviorSubject<BannerData> = new BehaviorSubject<BannerData>(null);
  cachedGetAssignedApps = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient,
              private httpHeaderService: HttpHeaderService) {
  }

  getAssignedApps(): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sort', 'appName,asc,ignoreCase');
    return this.httpClient
      .get(environment.portApiBaseUrl + this.baseUrl + 'apps/assigned', this.httpHeaderService.buildSimpleHeader(queryParams));
  }

  getAllApps(): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sort', 'appName,asc,ignoreCase');
    return this.httpClient.get(environment.portApiBaseUrl + this.baseUrl + 'apps', this.httpHeaderService.buildSimpleHeader(queryParams));
  }

  getAllForAdmin(): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('sort', 'appName,asc,ignoreCase');
    return this.httpClient
      .get(environment.portApiBaseUrl + this.baseUrl + 'apps/admin', this.httpHeaderService.buildSimpleHeader(queryParams));
  }

  getAppByAppCode(appCode: string): Observable<PBMAppInfo> {
    return this.httpClient
      .get<PBMAppInfo>(environment.portApiBaseUrl + this.baseUrl + 'app/' + appCode, this.httpHeaderService.buildSimpleHeader(null));
  }

  getPermissionsByAppCode(appCode: string): Observable<any> {
    return this.httpClient
      .get(environment.portApiBaseUrl + this.baseUrl + 'app/' + appCode + '/permissions', this.httpHeaderService.buildSimpleHeader(null));
  }

  updateApp(pbmAppInfo: PBMAppInfo): Observable<PBMAppInfo> {
    return this.httpClient
      .put<PBMAppInfo>(environment.portApiBaseUrl + this.baseUrl + 'app', pbmAppInfo, this.httpHeaderService.buildSimpleHeader(null));
  }

  addAppPermission(pbmPermissionInfo: PBMPermissionInfo): Observable<PBMPermissionInfo> {
    return this.httpClient
      .post<PBMPermissionInfo>(environment.portApiBaseUrl + this.baseUrl + 'app/permission',
        pbmPermissionInfo,
        this.httpHeaderService.buildSimpleHeader(null));
  }

  updateAppPermission(pbmPermissionInfo: PBMPermissionInfo): Observable<PBMPermissionInfo> {
    return this.httpClient
      .put<PBMPermissionInfo>(environment.portApiBaseUrl + this.baseUrl + 'app/permissions',
        pbmPermissionInfo,
        this.httpHeaderService.buildSimpleHeader(null));
  }

  deleteAppPermissionById(permissionId: number): Observable<any> {
    return this.httpClient
        .delete(environment.portApiBaseUrl + this.baseUrl + `app/permissions/${permissionId}`,
            this.httpHeaderService.buildSimpleHeader(null));
  }

  getRolesByAppCode(appCode: string): Observable<PBMRoleInfo[]> {
    return this.httpClient
      .get<PBMRoleInfo[]>(environment.portApiBaseUrl + this.baseUrl  + appCode + '/roles',
          this.httpHeaderService.buildSimpleHeader(null));
  }

  updateAppRole(pbmRoleInfo: PBMRoleInfo): Observable<PBMRoleInfo> {
    return this.httpClient
      .put<PBMRoleInfo>(environment.portApiBaseUrl + this.baseUrl + 'app/role',
        pbmRoleInfo,
        this.httpHeaderService.buildSimpleHeader(null));
  }

  deleteAppRoleById(roleId: number): Observable<any> {
    return this.httpClient
      .delete(environment.portApiBaseUrl + this.baseUrl + `app/role/${roleId}`,
        this.httpHeaderService.buildSimpleHeader(null));
  }

  createAppRole(pbmRoleInfo: PBMRoleInfo, appCode: string): Observable<any> {
    return this.httpClient
      .post(environment.portApiBaseUrl + this.baseUrl + `app/${appCode}/role`,
        pbmRoleInfo,
        this.httpHeaderService.buildSimpleHeader(null));
  }
}
