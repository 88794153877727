import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ColDef, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {AgGridHelper} from '../../ag-grid-utils/helpers/AgGridHelper';
import {ClientGroupManagementService} from '../../services/client-group-management/client-group-management.service';
import {DatePipe} from '@angular/common';
import {PortalGroupInfo} from '../../models/PortalGroupInfo';
import {
  ClientGroupTableActionCellRendererComponent
} from '../../ag-grid-utils/cell-renderers/client-group-table-action-cell-renderer/client-group-table-action-cell-renderer.component';
import {AddEditModeEnum} from '../../enums/add-edit-form.enum';
import {
  ClientGroupLobsRendererComponent
} from '../../ag-grid-utils/cell-renderers/client-group-lobs-renderer/client-group-lobs-renderer.component';
import {LineOfBusinessInfo} from '../../models/LineOfBusinessInfo';
import {LineOfBusinessService} from '../../services/line-of-business/line-of-business.service';
import {BannerService} from '../../services/banner-service/banner.service';
import {Subscription} from 'rxjs';
import {ClientGroupSearchComponent} from '../../ag-grid-utils/client-group-search/client-group-search.component';

@Component({
  selector: 'app-client-group-management',
  templateUrl: './client-group-management.component.html',
  styleUrls: ['./client-group-management.component.scss']
})
export class ClientGroupManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Group Management';
  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;
  defaultPaginatorSize = 50;
  subscriptions: Subscription[] = [];

  frameworkComponents = {
    clientGroupManagementTableActionCellRenderer: ClientGroupTableActionCellRendererComponent
  };

  isGroupDetailsShown = false;
  selectedGroup: PortalGroupInfo;

  lineOfBusinesses: LineOfBusinessInfo[];

  @ViewChild('searchGroupComponent') searchComponent: ClientGroupSearchComponent;

  showGrid: boolean;

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Client Group Name',
      cellRenderer: (params) => {
        const a = document.createElement('a');
        a.innerText = params.data.groupName;
        a.addEventListener('click', event => {
          event.stopPropagation();
          this.showGroupDetails(params);
        });
        return a;
      },
      getQuickFilterText: params => {
        return params.data.groupName;
      },
      filterValueGetter: (params) => {
        return params.data.groupName;
      },
      valueGetter: (params) => {
        return params.data.groupName;
      },
      minWidth: 250
    },
    {
      headerName: 'Clients',
      field: '',
      minWidth: 150,
      valueGetter: (params) => {
        const clientLength = params.data.clients.length;
        const label = clientLength > 1 ? ' clients' : ' client';
        return clientLength + label;
      },
    },
    {
      headerName: 'Group Type',
      field: 'groupType',
      minWidth: 150,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Owner',
      colId: 'owner',
      field: '',
      valueGetter: (params) => {
        return params.data.owner?.basicUserInfo.firstName + ' ' + params.data.owner?.basicUserInfo.lastName;
      },
      getQuickFilterText: params => {
        return params.data.owner?.basicUserInfo.firstName + ' ' + params.data.owner?.basicUserInfo.lastName;
      },
      minWidth: 250,
    },
    {
      headerName: 'Owner\'s Email',
      field: '',
      minWidth: 300,
      valueGetter: (params) => {
        return params.data.owner?.basicUserInfo.email;
      }
    },
    {
      headerName: 'Line of Business',
      field: '',
      minWidth: 200,
      sortable: false,
      valueGetter: (params) => {
        return params.data.lineOfBusinesses.map(lob => lob.name);
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => {
          const filterOptions = [];

          for (const lobs of params.context.this.lineOfBusinesses) {
            filterOptions.push(lobs.name);
          }

          params.success(filterOptions);
        },
        comparator: (a, b) => {
          return a.localeCompare(b);
        }
      },
      cellRenderer: ClientGroupLobsRendererComponent,
    },
    {
      headerName: 'Status',
      field: 'groupStatus',
      minWidth: 120,
      filter: 'agSetColumnFilter'
    },
    {
      headerName: 'Actions',
      field: '',
      sortable: false,
      suppressMenu: true,
      minWidth: 100,
      cellRenderer: 'clientGroupManagementTableActionCellRenderer',
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      minWidth: 200,
      valueGetter: (params) => {
        return this.datePipe.transform(params.data.createdDate, 'MM/dd/yyyy');
      },
      filter: 'agDateColumnFilter',
      filterParams: AgGridHelper.dateFilterParams()
    },
    {
      headerName: 'Last Modified Date',
      field: 'lastModifiedDate',
      minWidth: 200,
      valueGetter: (params) => {
        return this.datePipe.transform(params.data.lastModifiedDate, 'MM/dd/yyyy');
      },
      filter: 'agDateColumnFilter',
      filterParams: AgGridHelper.dateFilterParams()
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      minWidth: 200,
    },
    {
      headerName: 'Last Modified By',
      field: 'lastModifiedBy',
      minWidth: 200,
    },
  ];

  constructor(private clientGroupManagementService: ClientGroupManagementService,
              private lineOfBusinessService: LineOfBusinessService,
              private bannerService: BannerService,
              private router: Router,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.lineOfBusinessService.getLineOfBusinesses().subscribe((response) => {
      this.lineOfBusinesses = response;
      this.lineOfBusinesses.sort((lob1, lob2) => lob1.name > lob2.name ? 1 : -1);
    });
  }

  ngAfterViewInit(){
    const sub = this.clientGroupManagementService.clientGroupManagementNotification
      .subscribe(data => {
        if(data){
          this.bannerService.showSuccessBanner(data);
          this.clientGroupManagementService.clientGroupManagementNotification.next(null);
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  navigateToAddEditGroup() {
    this.router.navigate(['/client-group-management/add'], {state: {mode: AddEditModeEnum.ADD}});
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.clientGroupManagementService.getAllGroups().subscribe(result => {
      result.sort((groupInfoOne, groupInfoTwo) => {
        return groupInfoOne.groupName.localeCompare(groupInfoTwo.groupName);
      });
      this.gridApi.setRowData(result);
    });
  }

  showGroupDetails(param) {
    this.isGroupDetailsShown = true;
    this.selectedGroup = param.data;
  }

  hideGroupDetails() {
    this.isGroupDetailsShown = false;
    this.selectedGroup = null;
  }
}
