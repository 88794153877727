import {Injectable, OnDestroy} from '@angular/core';
import {PBMUserInfo} from '../../models/User';
import {BehaviorSubject, Observable, Subject, Subscription, tap} from 'rxjs';
import {AuthorizationService, GetUserNameService} from 'angular-component-library';
import {UserManagementService} from '../user-management/user-management.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService implements OnDestroy, GetUserNameService {

  private authorizationSubscription: Subscription;

  currentUser$: BehaviorSubject<PBMUserInfo>;
  logoutStop$ = new Subject();
  logoutStart$ = new Subject();

  constructor(authorizationService: AuthorizationService,
    private userManagementService: UserManagementService) {
    this.currentUser$ = new BehaviorSubject<PBMUserInfo>(null);
    this.authorizationSubscription = authorizationService.authorizationSuccess.subscribe(authorizationResponse => {
      this.currentUser$.next(authorizationResponse.user);
    });
  }

  getWelcomeMessage(user: PBMUserInfo): string {
    if (user) {
      return 'Welcome, ' + user.basicUserInfo.firstName + ' ' + user.basicUserInfo.lastName;
    }

    return '';
  }

  getCurrentUser(): Observable<PBMUserInfo> {
    return this.userManagementService.getUser().pipe( tap( (result) => {
      this.currentUser$.next(result);
    }));
  }

  ngOnDestroy(): void {
    this.authorizationSubscription.unsubscribe();
  }
}
