import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {
  AuthCodePKCEService,
  AuthenticationService,
  CVSBannerModule,
  CVSBaseModule,
  CVSChipInputModule,
  CVSConfirmationDialogModule,
  CVSLoaderModule,
  CVSMultiSelectModule,
  CVSPopoverButtonModule,
  CVSToggleSwitchModule,
  GetUserNameService,
  TooltipModule
} from 'angular-component-library';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {TileComponent} from './tile/tile.component';

import {AppManagementComponent} from './app-management/app-management.component';
import {ClientManagementComponent} from './client-management/client-management.component';
import {NoAccessLandingPageComponent} from './no-access-landing-page/no-access-landing-page.component';
import {CurrentUserService} from './services/current-user/current-user.service';
import {LegalModalComponent} from './common/legal-modal/legal-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from './footer/footer.component';
import {SaveChangesDialogComponent} from './common/save-changes-dialog/save-changes-dialog.component';
import {AgGridModule} from 'ag-grid-angular';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AppDashboardComponent} from './app-management/app-dashboard/app-dashboard.component';
import {MatTabsModule} from '@angular/material/tabs';
import {AppPermissionsComponent} from './app-management/app-dashboard/app-permissions/app-permissions.component';
import {AppOverviewComponent} from './app-management/app-dashboard/app-overview/app-overview.component';
import {
  AddEditPermissionComponent
} from './app-management/app-dashboard/app-permissions/add-edit-permission/add-edit-permission.component';
import {MatInputModule} from '@angular/material/input';
import {
  TableActionCellRendererComponent
} from './ag-grid-utils/cell-renderers/table-action-cell-renderer/table-action-cell-renderer.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import {AppRolesComponent} from './app-management/app-dashboard/app-roles/app-roles.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AddEditRoleComponent} from './app-management/app-dashboard/app-roles/add-edit-role/add-edit-role.component';
import {
  RoleCellRendererComponent
} from './ag-grid-utils/cell-renderers/role-cell-renderer/role-cell-renderer.component';
import {
  RoleTableActionCellRendererComponent
} from './ag-grid-utils/cell-renderers/role-table-action-cell-renderer/role-table-action-cell-renderer.component';
import {SearchComponent} from './ag-grid-utils/search/search.component';
import {
  ExpandCollapseButtonsComponent
} from './ag-grid-utils/expand-collapse-buttons/expand-collapse-buttons.component';
import {MatSelectModule} from '@angular/material/select';
import {PaginatorModule} from './ag-grid-utils/paginator-rows-per-page/paginator.module';
import {EnvironmentService} from '../environments/EnvironmentService';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ClientSearchComponent} from './ag-grid-utils/client-search/client-search.component';
import {AddClientComponent} from './client-management/add-client/add-client.component';
import {ClientProfileComponent} from './client-management/client-profile/client-profile.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserTableComponent} from './user-management/user-table/user-table.component';
import {UserAddEditComponent} from './user-management/user-add-edit/user-add-edit.component';
import {
  UserAppAccessRoleGridComponent
} from './user-management/user-app-access-role-grid/user-app-access-role-grid.component';
import {
  UserGeneralAccessGridComponent
} from './user-management/user-general-access-grid/user-general-access-grid.component';
import {
  GeneralAccessPermissionTypeRendererComponent
} from './ag-grid-utils/cell-renderers/general-access-permission-type-renderer/general-access-permission-type-renderer.component';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {HttpClientModule} from '@angular/common/http';
import {MatChipsModule} from '@angular/material/chips';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatButtonModule} from '@angular/material/button';
import {A11yModule} from '@angular/cdk/a11y';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {UserClientAccessComponent} from './user-management/user-client-access/user-client-access.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ClientPaginatorComponent} from './ag-grid-utils/client-paginator/client-paginator.component';
import {ClientProfileModalComponent} from './common/client-profile-modal/client-profile-modal.component';
import {
  UserAppAccessRendererComponent
} from './ag-grid-utils/cell-renderers/user-app-access-renderer/user-app-access-renderer.component';
import {UsersReportComponent} from './report-management/users-report/users-report.component';
import {ClientsReportComponent} from './report-management/clients-report/clients-report.component';
import {ClientSelectorComponent} from './ag-grid-utils/client-selector/client-selector.component';
import {
  ClientGroupManagementComponent
} from './client-management/client-group-management/client-group-management.component';
import {AddEditGroupComponent} from './client-management/add-edit-group/add-edit-group.component';
import {
  ClientGroupDetailComponent
} from './client-management/client-group-management/client-group-detail/client-group-detail.component';
import {
  ClientGroupTableActionCellRendererComponent
} from './ag-grid-utils/cell-renderers/client-group-table-action-cell-renderer/client-group-table-action-cell-renderer.component';
import {
  ClientGroupLobsRendererComponent
} from './ag-grid-utils/cell-renderers/client-group-lobs-renderer/client-group-lobs-renderer.component';
import {
  UserClientGroupAccessComponent
} from './user-management/user-client-group-access/user-client-group-access.component';
import {
  UserManagementTableActionCellRendererComponent
} from './ag-grid-utils/cell-renderers/user-management-table-action-cell-renderer/user-management-table-action-cell-renderer.component';
import {ClientGroupModalComponent} from './common/client-group-modal/client-group-modal.component';
import {ClientGroupSearchComponent} from './ag-grid-utils/client-group-search/client-group-search.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    TileComponent,
    AppManagementComponent,
    ClientManagementComponent,
    NoAccessLandingPageComponent,
    LegalModalComponent,
    FooterComponent,
    SaveChangesDialogComponent,
    AppDashboardComponent,
    AppPermissionsComponent,
    AppOverviewComponent,
    AddEditPermissionComponent,
    TableActionCellRendererComponent,
    AppRolesComponent,
    AddEditRoleComponent,
    RoleCellRendererComponent,
    SearchComponent,
    RoleTableActionCellRendererComponent,
    ExpandCollapseButtonsComponent,
    AddClientComponent,
    ClientSearchComponent,
    ClientProfileComponent,
    UserManagementComponent,
    UserTableComponent,
    UserAddEditComponent,
    UserAppAccessRoleGridComponent,
    UserGeneralAccessGridComponent,
    GeneralAccessPermissionTypeRendererComponent,
    UserClientAccessComponent,
    ClientPaginatorComponent,
    ClientProfileModalComponent,
    ClientGroupModalComponent,
    UserAppAccessRendererComponent,
    UsersReportComponent,
    ClientsReportComponent,
    ClientSelectorComponent,
    ClientGroupManagementComponent,
    AddEditGroupComponent,
    ClientGroupDetailComponent,
    ClientGroupTableActionCellRendererComponent,
    ClientGroupLobsRendererComponent,
    UserClientGroupAccessComponent,
    UserManagementTableActionCellRendererComponent,
    ClientGroupSearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CVSBaseModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatGridListModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CVSLoaderModule,
    AgGridModule,
    TooltipModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatCheckboxModule,
    CVSToggleSwitchModule,
    CVSPopoverButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    CVSConfirmationDialogModule,
    MatRadioModule,
    PaginatorModule,
    MatMenuModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    CVSBannerModule,
    HttpClientModule,
    MatChipsModule,
    OverlayModule,
    MatButtonModule,
    A11yModule,
    MatListModule,
    MatExpansionModule,
    MatPaginatorModule,
    CVSChipInputModule,
    CVSMultiSelectModule
  ],

  providers: [
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: EnvironmentService) => () => ds.applyOverrides(),
      deps: [EnvironmentService],
      multi: true
    },
    {provide: AuthenticationService, useClass: AuthCodePKCEService},
    {provide: GetUserNameService, useClass: CurrentUserService},
    {provide: Window, useValue: window}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
