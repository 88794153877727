import {Component} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {
  ClientGroupManagementComponent
} from '../../../client-management/client-group-management/client-group-management.component';
import {BannerService} from '../../../services/banner-service/banner.service';
import {PortalGroupInfo} from '../../../models/PortalGroupInfo';
import {ClientGroupManagementService} from '../../../services/client-group-management/client-group-management.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AddEditModeEnum, StatusCodes} from '../../../enums/add-edit-form.enum';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {PBMUserInfo} from '../../../models/User';

@Component({
  selector: 'app-client-group-management-table-action-cell-renderer',
  templateUrl: './client-group-table-action-cell-renderer.component.html',
  styleUrls: ['./client-group-table-action-cell-renderer.component.scss']
})
export class ClientGroupTableActionCellRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  parentComponent: ClientGroupManagementComponent;
  confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent>;

  showDisabledAction: boolean;
  showEnabledAction: boolean;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private bannerService: BannerService,
    private clientGroupManagementService: ClientGroupManagementService
  ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.parentComponent = this.params.context.this;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setTableActionClicked(tableActionClicked: boolean) {
    const data = this.params.node.data;
    data.tableActionClicked = tableActionClicked;
    this.showDisabledAction = this.showDisableMenuItem(this.params.node.data.groupStatus);
    this.showEnabledAction = this.showEnableMenuItem(this.params.node.data.groupStatus);
  }

  showDisableMenuItem(status: string) {
    return status === StatusCodes.ACTIVE || status === StatusCodes.PENDING;
  }

  showEnableMenuItem(status: string) {
    return status === StatusCodes.INACTIVE || status === StatusCodes.PENDING;
  }

  onDelete() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to delete this client group?',
        body:
          '<br>'
          + '<p class="delete-spacing">If this Client Group is not currently linked to any Users Profiles, it will no longer </p>'
          + 'be available for use.'
          + '<br>'
          + '<br>'
          + '<br> Warning: This cannot be undone.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false,
      },
      panelClass: 'delete-confirmation-modal'
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      const group = this.params.node.data;
      this.confirmationDialog.close();

      this.clientGroupManagementService.deleteGroup(group.id).subscribe({
        next: this.deleteGroupSuccessHandler.bind(this, group),
        error: this.deleteGroupFailureHandler.bind(this)
      });
    });


  }

  private deleteGroupSuccessHandler(deletedGroup: PortalGroupInfo) {
    this.params.api.forEachNode(node => {
      if (node.data.id === deletedGroup.id) {
        this.params.api.applyTransaction({remove: [node.data]});
      }
    });
    this.bannerService.showSuccessBanner({
      headLine : `${deletedGroup.groupName} has been deleted`,
      body : `The client group ${deletedGroup.groupName} has been successfully deleted.`,
      removedAfterMilliseconds: 5000
    });
  }

  private deleteGroupFailureHandler(err: HttpErrorResponse) {
    this.bannerService.showWarningBanner({
      headLine : 'Group cannot be deleted',
      body : err.error.message,
    });
  }

  navigateToAddEditClientGroup() {
    this.router.navigate(['/client-group-management/edit'], {
      state: {
        data: this.params.data,
        mode: AddEditModeEnum.EDIT
      }
    });
  }

  navigateToAddEditClientGroupCopyMode() {
    this.router.navigate(['/client-group-management/add'], {
      state: {
        data: this.params.data,
        mode: AddEditModeEnum.ADD
      }
    });
  }

  openEnableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to activate the selected client group?',
        body:
          '<br>'
          + 'The client group will become available for User Profiles.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.activateClientGroup();
    });
  }

  openDisableConfirmationModal() {
    this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
      data: {
        headline: 'Are you sure you want to inactivate the selected client group?',
        body:
          '<br>'
          + 'The client group will not be available for User Profiles.'
          + '<br>',
        actionLabel: 'Yes',
        cancelLabel: 'Cancel',
        noCloseX: false
      }
    });

    this.confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
      this.inactivateClientGroup();
    });
  }

  activateClientGroup() {
    const portalGroupInfo: PortalGroupInfo = this.params.data;
    portalGroupInfo.owner = {
      basicUserInfo: {
        firstName: portalGroupInfo.owner.basicUserInfo.firstName,
        lastName: portalGroupInfo.owner.basicUserInfo.lastName,
        email: portalGroupInfo.owner.basicUserInfo.email
      }
    } as PBMUserInfo;
    portalGroupInfo.users = portalGroupInfo.users.map(user => (
      {
        userPK: user.userPK
      } as PBMUserInfo
    ));
    portalGroupInfo.groupStatus = StatusCodes.ACTIVE;
    this.clientGroupManagementService.updateGroup(portalGroupInfo).subscribe({
      next: this.handleActivateGroupSuccess.bind(this, portalGroupInfo)
    });
  }

  inactivateClientGroup() {
    const portalGroupInfo: PortalGroupInfo = this.params.data;
    portalGroupInfo.groupStatus = StatusCodes.INACTIVE;
    portalGroupInfo.owner = {
      basicUserInfo: {
        firstName: portalGroupInfo.owner.basicUserInfo.firstName,
        lastName: portalGroupInfo.owner.basicUserInfo.lastName,
        email: portalGroupInfo.owner.basicUserInfo.email
      }
    } as PBMUserInfo;
    portalGroupInfo.users = portalGroupInfo.users.map(user => (
      {
        userPK: user.userPK
      } as PBMUserInfo
    ));
    this.clientGroupManagementService.updateGroup(portalGroupInfo).subscribe({
      next: this.handleInactivateGroupSuccess.bind(this, portalGroupInfo)
    });
  }

  handleActivateGroupSuccess(group: PortalGroupInfo) {
    this.params.node.data.groupStatus = StatusCodes.ACTIVE;
    this.params.node.data.owner = group.owner;
    this.params.node.data.users = group.users;
    this.params.api.applyTransaction({update: [this.params.node.data]});
    this.bannerService.showSuccessBanner({
      headLine : `${group.groupName} has been activated`,
      body : `The client group ${group.groupName} has been successfully activated.`,
      removedAfterMilliseconds: 5000
    });
  }

  handleInactivateGroupSuccess(group: PortalGroupInfo) {
    this.params.node.data.groupStatus = StatusCodes.INACTIVE;
    this.params.node.data.owner = group.owner;
    this.params.node.data.users = group.users;
    this.params.api.applyTransaction({update: [this.params.node.data]});
    this.bannerService.showSuccessBanner({
      headLine : `${group.groupName} has been inactivated`,
      body : `The client group ${group.groupName} has been successfully inactivated.`,
      removedAfterMilliseconds: 5000
    });
  }
}
