import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ColumnApi, GridApi, IServerSideDatasource, IServerSideGetRowsParams, RowModelType,} from 'ag-grid-community';
import {AgGridHelper} from '../ag-grid-utils/helpers/AgGridHelper';
import {ClientManagementService} from '../services/client-management/client-management.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {
  accountId,
  accountLevel,
  accountName,
  carrierId,
  carrierName,
  clientCode,
  clientCodeWithNoFilter,
  clientName,
  clientProfile,
  fromDate,
  marketSegment,
  rdcLevel,
  statusWithFilter,
  superClientId,
  superClientName,
  superClientRdcAccountWithFilter,
  thruDate
} from '../models/ClientHierarchyColumns';
import {ClientSearchComponent} from '../ag-grid-utils/client-search/client-search.component';
import {ClientSearchType} from '../enums/client-search-type.enum';
import {BannerService} from '../services/banner-service/banner.service';
import {Subscription} from 'rxjs';
import {SuperClient} from '../models/ClientHierarchy';
import {ClientDataService} from '../services/client-profile/data/client-data.service';
import {ClientProfileService} from '../services/client-profile/client-profile.service';
import {ClientHierarchyEnum} from '../enums/client-hierarchy-enum';

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements  OnDestroy, AfterViewInit {
  @ViewChild('clientSearchComponent') clientSearchComponent: ClientSearchComponent;

  title = 'Client Profile';
  subscriptions: Subscription[] = [];

  superClientIdColumnDefs = [
    superClientId,
    superClientName,
    clientProfile,
    accountId,
    accountName,
    accountLevel,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  superClientNameColumnDefs = [
    superClientName,
    superClientId,
    clientProfile,
    accountId,
    accountName,
    accountLevel,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  sfdcAccountIdColumnDefs = [
    accountId,
    accountName,
    clientProfile,
    superClientId,
    superClientName,
    statusWithFilter,
    accountLevel,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  sfdcAccountNameColumnDefs = [
    accountName,
    accountId,
    clientProfile,
    accountLevel,
    statusWithFilter,
    superClientId,
    superClientName,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  clientCodeColumnDefs = [
    clientCodeWithNoFilter,
    clientProfile
  ];

  clientNameColumnDefs = [
    clientName,
    superClientId,
    clientCode,
    carrierId,
    clientProfile,
    accountId,
    accountName,
    accountLevel,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    marketSegment
  ];

  carrierIdColumnDefs = [
    carrierId,
    carrierName,
    clientProfile,
    clientCode,
    statusWithFilter,
    superClientRdcAccountWithFilter,
    rdcLevel,
    fromDate,
    thruDate
  ];

  carrierNameColumnDefs = [
    carrierName,
    carrierId,
    clientProfile,
    fromDate,
    thruDate
  ];

  clientManagementOptions = [
    {
      label: ClientSearchType.SUPER_CLIENT_ID,
      colDef: this.superClientIdColumnDefs,
      searchParam: 'superClientId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SUPER_CLIENT_NAME,
      colDef: this.superClientNameColumnDefs,
      searchParam: 'superClientName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.CLIENT_CODE,
      colDef: this.clientCodeColumnDefs,
      searchParam: 'clientCode',
      accountLevel: ClientHierarchyEnum.CLIENT_CODE
    },
    {
      label: ClientSearchType.CLIENT_NAME,
      colDef: this.clientNameColumnDefs,
      searchParam: 'clientName',
      accountLevel: null
    },
    {
      label: ClientSearchType.CARRIER_ID,
      colDef: this.carrierIdColumnDefs,
      searchParam: 'carrierId',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.CARRIER_NAME,
      colDef: this.carrierNameColumnDefs,
      searchParam: 'carrierName',
      accountLevel: ClientHierarchyEnum.CARRIER
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_ID,
      colDef: this.sfdcAccountIdColumnDefs,
      searchParam: 'accountId',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    },
    {
      label: ClientSearchType.SALESFORCE_ACCOUNT_NAME,
      colDef: this.sfdcAccountNameColumnDefs,
      searchParam: 'accountName',
      accountLevel: ClientHierarchyEnum.SUPER_CLIENT
    }
  ];

  clientManagementOption = this.clientManagementOptions[0];
  clientSearchType: ClientSearchType = this.clientManagementOption.label;

  context = {this: this};
  gridApi: GridApi;
  columnApi: ColumnApi;
  rowModelType: RowModelType = 'serverSide';
  isGridReady = false;
  showGrid = false;
  defaultPaginatorSize = 50;
  errorTextMessage: string;

  constructor(private clientManagementService: ClientManagementService,
              private router: Router,
              private bannerService: BannerService,
              private clientDataService: ClientDataService,
              private clientProfileService: ClientProfileService) {
    if (this.router.getCurrentNavigation()?.extras?.state?.clientNotFound) {
        this.showGrid = false;
        this.errorTextMessage = 'We couldn’t find a match within CVS/Caremark. Check your spelling or refine your search.';
        this.setClientManagementOption(this.router.getCurrentNavigation()?.extras?.state?.selectedOption);
    }
  }

  defaultColDef = {
    flex: 1,
    width: 100,
    minWidth: 100,
    sortable: false,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['reset', 'apply'],
      filterOptions: ['equals', 'notEqual', 'contains', 'notContains', 'startsWith', 'endsWith'],
      defaultOption: 'contains',
      closeOnApply: false,
    },
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    cellStyle: {display: 'block'},
    comparator: (valueA, valueB) => {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    },
    suppressKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
    suppressHeaderKeyboardEvent: (params) => AgGridHelper.suppressTab(params),
  };

  onGridReady(params) {
    this.isGridReady = true;
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    AgGridHelper.insertRowsPerPageSelector();
    this.gridApi.paginationSetPageSize(this.defaultPaginatorSize);
    this.createServerSideData();
  }

  ngAfterViewInit(): void {
    const sub = this.clientManagementService.clientManagementNotification
      .subscribe(data => {
        if(data){
          this.bannerService.showSuccessBanner(data);
          this.clientManagementService.clientManagementNotification.next(null);
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  createServerSideData() {
    const datasource: IServerSideDatasource = this.createServerSideDatasource();
    this.gridApi.setServerSideDatasource(datasource);
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.gridApi.setColumnDefs(this.clientManagementOption.colDef);
        this.clientManagementService.searchClients(
                    this.clientManagementOption.accountLevel,
                    this.clientManagementOption.searchParam,
                    params.request.startRow,
                    params.request.endRow,
                    params.request.filterModel).subscribe({
            next: this.handleGetClientDataSuccess.bind(this, params),
            error: this.handleGetClientDataFailure.bind(this, params)
          });
      }
    };
  }

  private handleGetClientDataSuccess(params: IServerSideGetRowsParams, response: any) {
    params.success({
      rowData: response.data,
      rowCount: response.count
    });
  }

  private handleGetClientDataFailure(params: IServerSideGetRowsParams, err: HttpErrorResponse) {
    params.fail();
    this.errorTextMessage = err.error.message;
    this.showGrid = false;
    if (err.error.status === 404) {
      if (this.clientManagementOption.label !== ClientSearchType.CLIENT_NAME) {
        this.navigateToAddClient();
      } else {
        this.errorTextMessage = 'We couldn’t find a match within CVS/Caremark. Check your spelling or refine your search.';
      }
    }
  }

  navigateToAddClient() {
    this.router.navigate(['client-management/add'], {
      state: {
        selectedOption: this.clientManagementOption.label === ClientSearchType.CLIENT_NAME ?
            ClientSearchType.SUPER_CLIENT_ID : this.clientManagementOption.label,
        searchValue: this.clientManagementOption.label === ClientSearchType.CLIENT_NAME ? '' : this.clientSearchComponent.searchValue.value
      }
    });
  }

  getClientManagementData(searchValue) {
    this.clientManagementService.globalSearchValue = searchValue;
    this.showGrid = true;
    this.errorTextMessage = null;

    if (this.isGridReady) {
      this.createServerSideData();
    }
  }

  onDropDownChange() {
    this.clientSearchType = this.clientManagementOption.label;
  }

  setCurrentClient(client: SuperClient) {
    this.clientDataService.setCurrentClient(client);
  }

  getFullHierarchyForClientAccess(clientId) {
    this.clientProfileService.getClientProfile(clientId).subscribe(res => {
      this.setCurrentClient(res);
      this.router.navigate(['client-management/client-profile']);
    });
  }

  setClientManagementOption(clientManagementLabel: string){
    const filteredClientManagementOption = this.clientManagementOptions
                                 .filter((clientManagementOptionItem) => clientManagementOptionItem.label === clientManagementLabel);
    this.clientManagementOption = filteredClientManagementOption[0];
    this.onDropDownChange();
  }
}
