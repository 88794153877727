import {PBMPermissionInfo} from './PBMPermissionInfo';
import {PortalGroupInfo} from './PortalGroupInfo';
import {ClientDTO} from './ClientDTO';

export class GraphApiUser {
  id: string;
  displayName: string;
  givenName: string;
  mail: string;
  surname: string;
  businessPhones: string[];
  mobilePhone: string;
  jobTitle: string;
  officeLocation: string;
  role?: string;
  assignedClient?: string;
  assignedClientCode?: string;
  active?: boolean;
  offshoreUser?: boolean;
  clientConfig?: boolean;
  rerunAccess?: boolean;
  roleNames: string[];
}

export class UserFromToken {
  sub: string;
  extension_Active: boolean;
  extension_AssignedClient: string;
  extension_AssignedClientCode: string;
  extension_Role: string;
  family_name: string;
  given_name: string;
  email: string;
  name: string;
  extension_mail: string;
  extension_offshoreUser: boolean;
  extension_clientConfig: boolean;
  extension_rerunAccess: boolean;
  roleNames: string[];
}


export class BasicUserInfo {
  active: boolean;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
}

export class PBMUserInfo {
  userPK?: number;
  basicUserInfo: BasicUserInfo;
  employeeId: string;
  jobTitle: string;
  corporation: string;
  onshore: boolean;
  firm: string;
  adminUser: boolean;
  clientAdminUser: boolean;
  adminTypes?: string[];
  internalUser: boolean;
  accessType: string;
  permissions?: PBMPermissionInfo[];
  appRoles: object | string[];
  assignedPortalClients: ClientDTO[];
  assignedClientGroups: PortalGroupInfo[];
  termsAccepted?: boolean;
  emailPreference: boolean;
}
