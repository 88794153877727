import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpHeaderService} from '../services/http-header/http-header.service';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(
    private httpHeaderService: HttpHeaderService
  ) {}

  postAuditLog(actionType: string, actionString: string): void {
    const auditLog = {
      actionType,
      actionString,
    };

    const fetchHeaders = this.buildFetchHeaders();

    if(fetchHeaders.get('Authorization') !== 'Bearer null') {
      fetch(environment.portApiBaseUrl + '/api/v1/auditLog', {
        method: 'POST',
        headers: fetchHeaders,
        body: JSON.stringify(auditLog),
        keepalive: true}).then();
    }

  }

  private buildFetchHeaders() {
    const headers: HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', this.httpHeaderService.buildSimpleHeader().headers.get('Authorization'));
    return headers;
  }
}
